import "./Conditions.scss";

const Conditions = () => {
  return (
    <div className="conditions">
      <p className="bold-white">
        The fundraising campaign will run from March 21, 2023, to April 21,
        2023.
      </p>
      <p className="bold-white">
        Five people will be randomly selected among our donors on April 22,
        2023.
      </p>
      <p className="bold-white skip-after" id="about">
        The photoshoot will take place at{" "}
        <a href="https://www.ukrainehouse.us/" target="_blank" rel="noreferrer">
          Ukraine House
        </a>{" "}
        on April 29, 2023 and on May 6, 2023.
      </p>
      <p>
        The package includes <span>one look</span> for the photoshoot, make-up,
        and <span>three professionally edited digital images.</span>
      </p>
      <p>Please note all donations are non-refundable.</p>
    </div>
  );
};

export default Conditions;
